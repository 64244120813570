export default {
  projectList: state => state.projectList,
  projectListLength: state => state.projectList.length,
  projectType: state => state.projectType,
  importProjectId: state => state.importProjectId,
  isProjectLoading: state => state.isProjectLoading,
  selectedProjectId: state => state.selectedProjectId,
  isProjectCreate: state => state.isProjectCreate,
  isProjectDelete: state => state.isProjectDelete,
  isProjectRestore: state => state.isProjectRestore,
  isProjectClone: state => state.isProjectClone,
  isProjectArchive: state => state.isProjectArchive,
  isProjectUnArchive: state => state.isProjectUnArchive,
  isAprProjectMove: state => state.isAprProjectMove,
  projectMyTaskToday: state => state.projectMyTaskToday,
  needStubForProject: state => state.stubForImportKtd,
}
