import axios from '@/store/axios'
import { formatDateTimeToRussianListPattern } from '@/Utils/helpers'

export default {
  async downloadListVideoSources({ commit }, payload) {
    try {
      commit('setVideoSourceLoading', true)

      const response = await axios.get(`/horizon/video-source${payload.args}`)

      if (response) {
        commit('setVideoSources', response.data)
        commit('setTotal', response.total)
        commit('setTotalPage', response.total_pages)
        commit('setPerPage', response.per_page)
      }
    } catch (error) {
      console.error(error)
    } finally {
      commit('setVideoSourceLoading', false)
    }
  },
  async sendCombinationData({ commit }, payload) {
    try {
      commit('setSendDataLoading', true)
      await axios.post('/horizon/video-source/training', { cameras_id: payload.cameras_id })
      commit('Notify/setSuccess', 'Данные успешно отправлены', { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      commit('setSendDataLoading', false)
    }
  },
  async saveCombinationData({ commit }, payload) {
    try {
      commit('setSaveDataLoading', true)
      await axios.post('/horizon/video-source/bind', payload)
      commit('Notify/setSuccess', 'Данные успешно сохранены', { root: true })
    } catch (error) {
      console.error(error)
    } finally {
      commit('setSaveDataLoading', false)
    }
  },
  async getCombinationData({ commit }, payload) {
    try {
      commit('setDataDownloading', true)
      const response = await axios.get('/horizon/video-source/bind/show', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })
      return response.origin_data
    } catch (error) {
      console.error(error)
    } finally {
      commit('setDataDownloading', false)
    }
  },
  async startVideoAnalyticProcess({ commit }) {
    try {
      commit('setStartVideoAnalyticLoading', true)
      commit('addVideoAnalyticMessages', {
        id: Math.floor(Math.random() * 1000),
        type: 'Start',
        name: 'Request to launch video analytics started',
        date: formatDateTimeToRussianListPattern(new Date()),
      })

      await axios.post('/statanly/start')

      commit('addVideoAnalyticMessages', {
        id: Math.floor(Math.random() * 1000),
        type: 'Start',
        name: 'Request to launch video analytics completed successfully',
        date: formatDateTimeToRussianListPattern(new Date()),
      })
    } catch (error) {
      console.error(error)
      commit('addVideoAnalyticMessages', {
        id: Math.floor(Math.random() * 1000),
        type: 'Failure',
        name: 'Запрос на запуск видеоаналитики завершен с ошибкой',
        date: formatDateTimeToRussianListPattern(new Date()),
      })
    } finally {
      commit('setStartVideoAnalyticLoading', false)
    }
  },
  async trainingStatus({ commit }, payload) {
    try {
      const res = await axios.get('/horizon/video-source/training/status', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })
      if (res['global+global']) {
        commit('Notify/setSuccess', `Для связки купольная + купольная: ${res['global+global']}`, { root: true })
      } else {
        commit('Notify/setSuccess', `Для связки купольная + лицевая: ${res['global+face']}`, { root: true })
      }
    } catch (error) {
      console.error(error)
    }
  },
  async trainingStart({ commit }, payload) {
    try {
      await axios.post('/horizon/video-source/training/start', payload)
      commit('Notify/setSuccess', 'Запуск выполнен.', { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async trainingStop({ commit }, payload) {
    try {
      await axios.post('/horizon/video-source/training/stop', payload)
      commit('Notify/setSuccess', 'Обучение остановлено.', { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async trainingInfo({ commit }, payload) {
    try {
      const res = await axios.get('/horizon/video-source/training/info', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })
      commit('Notify/setSuccess', `Собрано ${res.num_of_rows} строк`, { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async getVisualizationDataLink({ commit }, payload) {
    try {
      await axios.get('/horizon/video-source/link/video/training', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })
      commit('Ссыдка на видео успешно получена', { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async updateVisualizationData({ commit }, payload) {
    try {
      await axios.get('/horizon/video-source/download/video/training', {
        params: {
          'cameras_id[0]': payload.cameras_id[0],
          'cameras_id[1]': payload.cameras_id[1],
        },
      })
      commit('Успешное обновление видео', { root: true })
    } catch (error) {
      console.error(error)
    }
  },
  async deleteFrameFromVideo({ commit }, payload) {
    try {
      await axios.get(`/video-source/delete/fragment/video/training/${payload.fragment_id}`)
      commit('Кадр успешно удалён', { root: true })
    } catch (error) {
      console.error(error)
    }
  },
}
