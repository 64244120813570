export default {
  setStubForImportKtd: (state, data) => {
    state.stubForImportKtd = data.needStub
    localStorage.setItem('needStubForProject', JSON.stringify(data.project_id))
  },
  setProjectList: (state, data) => {
    if (data) {
      state.projectList = data
    }
  },
  setProjectType: (state, data) => {
    if (data) {
      state.projectType = data
    }
  },
  setNewProject: (state, data) => {
    if (data) {
      state.projectList.unshift(data)
    }
  },
  changeProject: (state, data) => {
    if (data) {
      const index = state.projectList.findIndex(project => project.id === data.id)

      if (index !== -1) {
        state.projectList[index] = data
      }
    }
  },
  setSelectedProjectId: (state, data) => {
    state.selectedProjectId = data
  },
  setDeleteProject: (state, data) => {
    if (data) {
      state.projectList = state.projectList.filter(item => item.id !== data)
    }
  },
  // Удаляем проекты из списка проектов АПР, которые имеют одинаковый repair id
  setDeleteAprProjectAfterMove: (state, repairId) => {
    const repairProjects = state.projectList.filter(project => {
      const currentRepairId = project?.repair?.id

      if (currentRepairId) {
        return currentRepairId === repairId
      }
      return project
    })

    if (repairProjects.length) {
      repairProjects.forEach(project => {
        if (project.id) {
          state.projectList = state.projectList.filter(item => item.id !== project.id)
        }
      })
    }
  },
  // Удаляем проекты из списка при перемещении между списками (учитывая принадлежность проектов к ремонтам)
  setDeleteProjectFromSomeList: (state, projectId) => {
    const currentProjectIndex = state.projectList.findIndex(project => project.id === projectId)
    if (currentProjectIndex !== -1) {
      const currentRepairId = state.projectList[currentProjectIndex]?.repair?.id

      if (currentRepairId) {
        // Проверяем принадлежит ли проект какому-либо ремонту
        const repairProjects = state.projectList.filter(project => {
          if (project?.repair?.id) {
            return currentRepairId === project?.repair?.id
          } else {
            return false
          }
        })

        if (repairProjects.length) {
          repairProjects.forEach(project => {
            if (project.id) {
              state.projectList = state.projectList.filter(item => item.id !== project.id)
            }
          })
        }
      } else {
        // Если проект не принадлежит никакому проекту, то просто удаляем его из списка
        state.projectList = state.projectList.filter(item => item.id !== projectId)
      }
    }
  },
  updateProject: (state, project) => {
    state.projectList.forEach((item, index) => {
      if (item.id === project.id) {
        state.projectList[index] = project
      }
    })
  },
  setImportProjectId: (state, projectId) => {
    state.importProjectId = projectId
  },
  setProjectCreate: (state, data) => {
    state.isProjectCreate = data
  },
  setProjectLoading: (state, data) => {
    state.isProjectLoading = data
  },
  setDeleteLoading: (state, data) => {
    state.isProjectDelete = data
  },
  setRestoreLoading: (state, data) => {
    state.isProjectRestore = data
  },
  setCloneLoading: (state, data) => {
    state.isProjectClone = data
  },
  setArchiveLoading: (state, data) => {
    state.isProjectArchive = data
  },
  setUnArchiveLoading: (state, data) => {
    state.isProjectUnArchive = data
  },
  setAprProjectMoveLoading: (state, data) => {
    state.isAprProjectMove = data
  },
  setProjectMyTaskToday: (state, data) => {
    state.projectMyTaskToday = data
  },
}
